import { usePage } from "@tecnobit-srl/router";
import { useMemo } from "react";
import { trimStart, join, map, omitBy } from "lodash-es";
const useThemeStyle = ({
  fontFamily,
  background,
  text,
  accent,
  overAccent,
  secondary,
  overSecondary,
  grey,
  error,
  valid
} = {}) => {
  const { settings } = usePage();
  return useMemo(() => {
    const style = {
      "--background": background ?? settings.color_background,
      "--text-200": (text == null ? void 0 : text["200"]) ?? settings.color_text_200,
      "--text-300": (text == null ? void 0 : text["300"]) ?? settings.color_text_300,
      "--text-500": (text == null ? void 0 : text["500"]) ?? settings.color_text_500,
      "--text-700": (text == null ? void 0 : text["700"]) ?? settings.color_text_700,
      "--accent-50": (accent == null ? void 0 : accent["50"]) ?? settings.color_accent_50,
      "--accent-100": (accent == null ? void 0 : accent["100"]) ?? settings.color_accent_100,
      "--accent-200": (accent == null ? void 0 : accent["200"]) ?? settings.color_accent_200,
      "--accent-300": (accent == null ? void 0 : accent["300"]) ?? settings.color_accent_300,
      "--accent-400": (accent == null ? void 0 : accent["400"]) ?? settings.color_accent_400,
      "--accent-500": (accent == null ? void 0 : accent["500"]) ?? settings.color_accent_500,
      "--accent-600": (accent == null ? void 0 : accent["600"]) ?? settings.color_accent_600,
      "--accent-700": (accent == null ? void 0 : accent["700"]) ?? settings.color_accent_700,
      "--accent-800": (accent == null ? void 0 : accent["800"]) ?? settings.color_accent_800,
      "--accent-900": (accent == null ? void 0 : accent["900"]) ?? settings.color_accent_900,
      "--accent-950": (accent == null ? void 0 : accent["950"]) ?? settings.color_accent_950,
      "--over-accent-50": (overAccent == null ? void 0 : overAccent["50"]) ?? settings.color_over_accent_50,
      "--over-accent-100": (overAccent == null ? void 0 : overAccent["100"]) ?? settings.color_over_accent_100,
      "--over-accent-200": (overAccent == null ? void 0 : overAccent["200"]) ?? settings.color_over_accent_200,
      "--over-accent-300": (overAccent == null ? void 0 : overAccent["300"]) ?? settings.color_over_accent_300,
      "--over-accent-400": (overAccent == null ? void 0 : overAccent["400"]) ?? settings.color_over_accent_400,
      "--over-accent-500": (overAccent == null ? void 0 : overAccent["500"]) ?? settings.color_over_accent_500,
      "--over-accent-600": (overAccent == null ? void 0 : overAccent["600"]) ?? settings.color_over_accent_600,
      "--over-accent-700": (overAccent == null ? void 0 : overAccent["700"]) ?? settings.color_over_accent_700,
      "--over-accent-800": (overAccent == null ? void 0 : overAccent["800"]) ?? settings.color_over_accent_800,
      "--over-accent-900": (overAccent == null ? void 0 : overAccent["900"]) ?? settings.color_over_accent_900,
      "--over-accent-950": (overAccent == null ? void 0 : overAccent["950"]) ?? settings.color_over_accent_950,
      "--secondary-50": (secondary == null ? void 0 : secondary["50"]) ?? settings.color_secondary_50,
      "--secondary-100": (secondary == null ? void 0 : secondary["100"]) ?? settings.color_secondary_100,
      "--secondary-200": (secondary == null ? void 0 : secondary["200"]) ?? settings.color_secondary_200,
      "--secondary-300": (secondary == null ? void 0 : secondary["300"]) ?? settings.color_secondary_300,
      "--secondary-400": (secondary == null ? void 0 : secondary["400"]) ?? settings.color_secondary_400,
      "--secondary-500": (secondary == null ? void 0 : secondary["500"]) ?? settings.color_secondary_500,
      "--secondary-600": (secondary == null ? void 0 : secondary["600"]) ?? settings.color_secondary_600,
      "--secondary-700": (secondary == null ? void 0 : secondary["700"]) ?? settings.color_secondary_700,
      "--secondary-800": (secondary == null ? void 0 : secondary["800"]) ?? settings.color_secondary_800,
      "--secondary-900": (secondary == null ? void 0 : secondary["900"]) ?? settings.color_secondary_900,
      "--secondary-950": (secondary == null ? void 0 : secondary["950"]) ?? settings.color_secondary_950,
      "--over-secondary-50": (overSecondary == null ? void 0 : overSecondary["50"]) ?? settings.color_over_secondary_50,
      "--over-secondary-100": (overSecondary == null ? void 0 : overSecondary["100"]) ?? settings.color_over_secondary_100,
      "--over-secondary-200": (overSecondary == null ? void 0 : overSecondary["200"]) ?? settings.color_over_secondary_200,
      "--over-secondary-300": (overSecondary == null ? void 0 : overSecondary["300"]) ?? settings.color_over_secondary_300,
      "--over-secondary-400": (overSecondary == null ? void 0 : overSecondary["400"]) ?? settings.color_over_secondary_400,
      "--over-secondary-500": (overSecondary == null ? void 0 : overSecondary["500"]) ?? settings.color_over_secondary_500,
      "--over-secondary-600": (overSecondary == null ? void 0 : overSecondary["600"]) ?? settings.color_over_secondary_600,
      "--over-secondary-700": (overSecondary == null ? void 0 : overSecondary["700"]) ?? settings.color_over_secondary_700,
      "--over-secondary-800": (overSecondary == null ? void 0 : overSecondary["800"]) ?? settings.color_over_secondary_800,
      "--over-secondary-900": (overSecondary == null ? void 0 : overSecondary["900"]) ?? settings.color_over_secondary_900,
      "--over-secondary-950": (overSecondary == null ? void 0 : overSecondary["950"]) ?? settings.color_over_secondary_950,
      "--grey-50": (grey == null ? void 0 : grey["50"]) ?? settings.color_grey_50,
      "--grey-100": (grey == null ? void 0 : grey["100"]) ?? settings.color_grey_100,
      "--grey-200": (grey == null ? void 0 : grey["200"]) ?? settings.color_grey_200,
      "--grey-300": (grey == null ? void 0 : grey["300"]) ?? settings.color_grey_300,
      "--grey-400": (grey == null ? void 0 : grey["400"]) ?? settings.color_grey_400,
      "--grey-500": (grey == null ? void 0 : grey["500"]) ?? settings.color_grey_500,
      "--grey-600": (grey == null ? void 0 : grey["600"]) ?? settings.color_grey_600,
      "--grey-700": (grey == null ? void 0 : grey["700"]) ?? settings.color_grey_700,
      "--grey-800": (grey == null ? void 0 : grey["800"]) ?? settings.color_grey_800,
      "--grey-900": (grey == null ? void 0 : grey["900"]) ?? settings.color_grey_900,
      "--grey-950": (grey == null ? void 0 : grey["950"]) ?? settings.color_grey_950,
      "--error-300": (error == null ? void 0 : error["300"]) ?? settings.color_error_300,
      "--error-500": (error == null ? void 0 : error["500"]) ?? settings.color_error_500,
      "--error-700": (error == null ? void 0 : error["700"]) ?? settings.color_error_700,
      "--valid-300": (valid == null ? void 0 : valid["300"]) ?? settings.color_valid_300,
      "--valid-500": (valid == null ? void 0 : valid["500"]) ?? settings.color_valid_500,
      "--valid-700": (valid == null ? void 0 : valid["700"]) ?? settings.color_valid_700,
      "--font-family": fontFamily ?? settings.fontFamily
    };
    return `
      @layer theme {
        :root {
          ${trimStart(
      join(
        map(
          omitBy(style, (v) => !v),
          (value, key) => `          ${value ? `${key}: ${value.toString()};` : ""}`
        ),
        "\n"
      )
    )}
        }
      }
    `;
  }, [
    background,
    settings.color_background,
    settings.color_text_200,
    settings.color_text_300,
    settings.color_text_500,
    settings.color_text_700,
    settings.color_accent_50,
    settings.color_accent_100,
    settings.color_accent_200,
    settings.color_accent_300,
    settings.color_accent_400,
    settings.color_accent_500,
    settings.color_accent_600,
    settings.color_accent_700,
    settings.color_accent_800,
    settings.color_accent_900,
    settings.color_accent_950,
    settings.color_over_accent_50,
    settings.color_over_accent_100,
    settings.color_over_accent_200,
    settings.color_over_accent_300,
    settings.color_over_accent_400,
    settings.color_over_accent_500,
    settings.color_over_accent_600,
    settings.color_over_accent_700,
    settings.color_over_accent_800,
    settings.color_over_accent_900,
    settings.color_over_accent_950,
    settings.color_secondary_50,
    settings.color_secondary_100,
    settings.color_secondary_200,
    settings.color_secondary_300,
    settings.color_secondary_400,
    settings.color_secondary_500,
    settings.color_secondary_600,
    settings.color_secondary_700,
    settings.color_secondary_800,
    settings.color_secondary_900,
    settings.color_secondary_950,
    settings.color_over_secondary_50,
    settings.color_over_secondary_100,
    settings.color_over_secondary_200,
    settings.color_over_secondary_300,
    settings.color_over_secondary_400,
    settings.color_over_secondary_500,
    settings.color_over_secondary_600,
    settings.color_over_secondary_700,
    settings.color_over_secondary_800,
    settings.color_over_secondary_900,
    settings.color_over_secondary_950,
    settings.color_grey_50,
    settings.color_grey_100,
    settings.color_grey_200,
    settings.color_grey_300,
    settings.color_grey_400,
    settings.color_grey_500,
    settings.color_grey_600,
    settings.color_grey_700,
    settings.color_grey_800,
    settings.color_grey_900,
    settings.color_grey_950,
    settings.color_error_300,
    settings.color_error_500,
    settings.color_error_700,
    settings.color_valid_300,
    settings.color_valid_500,
    settings.color_valid_700,
    settings.fontFamily,
    text,
    accent,
    overAccent,
    secondary,
    overSecondary,
    grey,
    error,
    valid,
    fontFamily
  ]);
};
export {
  useThemeStyle
};
